<template>

    <div class="h-full overflow-hidden relative">

        <div class="absolute bg-box-80 z-10 h-full w-full top-0 rounded-lg left-0" v-if="loading">
            <loader :loading="loading" />
        </div>

        <div v-if="mobile" class="h-full overflow-auto no-scrollbar pb-8">
            <pipeline-sl v-for="(el) in slSorted" :key='removeCharacters(el.id)' :data="el" @nextPage="nextPage"/>
        </div>

        <template v-else>
            <transition-group name="fade" tag="div" class="h-full overflow-y-auto">
                <div class="box mb-2" :key='removeCharacters(i.id)' v-for="i in slSorted">
                    <div class="flex items-center mb-4">
                        <div class="w-1 rounded-full h-6 mr-2"
                             :style="'background-color:' + legendColors[i.id].color + ';'">
                        </div>
                        <div class="text-2xl">{{ i.name }}</div>

                        <div class="h-8 w-8 rounded-lg flex flex-col justify-center items-center ml-auto cursor-pointer" :class="{'bg-aux-15 text-aux': slSelectedItems.includes(i.id), 'bg-filters-80 text-font-gray': !slSelectedItems.includes(i.id)}"
                             @click="toggleSlSelectedItems(i.id)">
                            <i class="mdi mdi-eye-outline" v-if="slSelectedItems.includes(i.id)"></i>
                            <i class="mdi mdi-eye-off-outline" v-else></i>
                        </div>

                    </div>
                    <div class="flex">
                        <div class="w-1/2 pr-4">
                            <div class="flex items-center h-7 mb-3">
                                <div class="text-tiny text-font-gray">Sales</div>
                                <div class="ml-auto text-xl font-semibold">
                                    {{ i.total_sales | reduceBigNumbers(2) }}€
                                </div>
                            </div>
                            <div class="flex items-center h-6 mb-4">
                                <div class="flex items-center">
                                    <div class="mr-4 text-font-dark text-sm">vs Obj</div>
                                    <div
                                        :class="successOrDangerClass(i.objective_sale,100)"
                                    ><span v-if="i.objective_sale>0">+</span>{{ i.objective_sale | numberFormat(2) }}<span v-if="i.objective_sale!='N/A'&&i.objective_sale!='TBD'">%</span>
                                    </div>
                                </div>
                                <div class="flex items-center ml-auto">
                                    <div class="mr-4 text-font-dark text-sm">vs Avg(SL)</div>
                                    <div><span v-if="i.avg_sale>0&&i.avg_sale!='TBD'">+</span>{{ i.avg_sale | numberFormat(2) }} <span v-if=" i.avg_sale!='TBD'">ptos.</span></div>
                                </div>
                            </div>
                            <div class="rounded-full h-2 bg-progress overflow-hidden relative">
                              <div class="rounded-full bg-success h-full absolute left-0" v-if="i.avg_sale!='TBD'"
                                   :style="'width:' + i.avg_sale + '%;'">
                              </div>
                              <div class="rounded-full bg-success h-full absolute left-0" v-else
                                   :style="'width:100%;'">
                              </div>
                              <div class="rounded-full bg-blue z-10 h-full absolute left-0" v-if="i.objective_sale!='TBD'"
                                   :style="'width:' + i.objective_sale + '%;'">
                              </div>
                              <div class="rounded-full bg-blue z-10 h-full absolute left-0" v-else
                                   :style="'width:100%;'">
                              </div>
                            </div>
                        </div>
                        <div class="w-3/10 px-4">
                            <div class="flex items-center h-7 mb-3">
                                <div class="text-tiny text-font-gray">Sales Estimated</div>
                                <div class="ml-auto text-xl font-semibold">
                                    {{ i.estimated_sales | reduceBigNumbers(2) }}€
                                </div>
                            </div>
                            <div class="flex justify-center items-center h-6 mb-4">
                                <div class="flex items-center">
                                    <div class="mr-4 text-font-dark text-sm">vs Obj</div>
                                    <div><span v-if="i.objective_estimated_sales>0">+</span>{{ i.objective_estimated_sales | numberFormat(2) }}<span v-if="i.objective_estimated_sales!='N/A'&&i.objective_estimated_sales!='TBD'">%</span></div>
                                </div>
                            </div>
                            <div class="rounded-full h-2 bg-progress flex overflow-hidden">
                                <div class="rounded-full bg-warning h-full" v-if="i.objective_estimated_sales!='N/A'&&i.objective_estimated_sales!='TBD'"
                                     :style="'width:' + i.objective_estimated_sales + '%;'">
                                </div>
                              <div class="rounded-full bg-warning h-full" v-else
                                   :style="'width:100%;'">
                              </div>
                            </div>
                        </div>
                        <div class="w-1/5 pl-4">
                            <div class="flex items-center h-7 mb-3">
                                <div class="text-tiny text-font-gray">Effectiveness ratio</div>
                            </div>
                            <div class="flex justify-center items-center h-6 mb-4">
                                <div class="text-xl">{{ i.effectiveness_ratio | numberFormat(2) }}%</div>
                            </div>
                            <div class="rounded-full h-2 bg-progress flex overflow-hidden">
                                <div class="rounded-full bg-danger h-full"
                                     :style="'width:' + i.effectiveness_ratio + '%;'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </template>

    </div>

</template>

<script>
    const PipelineSl = () => import('@/components/Mobile/Pipeline/PipelineSl')

    import { state, actions } from '@/store';
    export default {
        components: {
            PipelineSl
        },
        props: ['sortBy', 'sortOrder','mobile'],
        data() {
            return {
                sl: null,
                loading: false,
            }
        },
        computed: {
            params() { return state.globalParams },
            legendColors() { return state.slLegendColors },
            slSelectedItems() { return state.slSelectedItems },
            slSorted() {
                return this.sortBy ? this._.orderBy(this.sl , [this.sortBy.value], [this.sortBy.direction]) : this.sl
            }
        },
        watch: {
            params(n, o) { this.getSl() }
        },
        methods: {
            getSl() {
                if (!this.params) { return; }

                actions.clearSlSelectedItems();

                this.$emit('loadingSl', true);

                this.loading = true;

                this.axios.get('pipeline/sl', {params: this.params}).then(response => {

                        this.sl = response.data.data;
                        this.$setColorsBySector(this.sl)
                        this.loading = false
                        state.slSelectedItems = []
                        this.$emit('loadingSl', false)

                    })
            },
            toggleSlSelectedItems(item){ 
                actions.toggleSlSelectedItems(item) 
            },
            removeCharacters(str){ 
                return str.replace(/[^A-Z0-9]+/ig, "_") 
            },
            nextPage(){
                this.$emit('nextPage')
            }

        },
        mounted() { this.getSl() }
    }

</script>